import { Button } from '@mui/material';
import theme from '../styles/theme';

export interface ForbiddenProps {
  text?: string;
  emoji?: string;
  userLogout?: () => void;
  email?: string;
}

export const Forbidden = (props: ForbiddenProps) => {
  const {
    text = 'No tienes permiso para acceder a este recurso',
    emoji = ':(',
    userLogout,
    email,
  } = props;

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        textAlign: 'center',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <p
        style={{
          fontSize: 100,
          transform: 'rotate(90deg)  translateY(-8%)',
          margin: 0,
          padding: 0,
        }}
      >
        {emoji}
      </p>
      <p
        style={{
          marginBottom: 16,
          fontSize: 20,
        }}
      >
        {text}
      </p>
      {email && (
        <p
          style={{
            marginBottom: 16,
            fontSize: 16,
          }}
        >
          Usuario: {email}
        </p>
      )}
      {userLogout && (
        <Button
          variant="contained"
          size="medium"
          sx={{ marginTop: theme.spacing(4) }}
          onClick={userLogout}
        >
          Cerrar sesión
        </Button>
      )}
    </div>
  );
};
